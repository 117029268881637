import { memo } from 'react'
import { useTranslation } from 'react-i18next'
/* layouts */
import { BaseLayout, ScrollLayout } from '@layouts'
/* components */
import { motion } from 'framer-motion'
/* assets */
import { Stage1Icon, Stage2Icon } from '@assets'
/* styles */
import './index.scss'

const RoadmapView = () => {
    const [t] = useTranslation('global')

    const stages = [
        {
            title: t('about.roadmap-stage'),
            date: '10-02-2023',
            subtitle: t('about.roadmap-subtitle'),
            icon: <Stage1Icon />,
        },
        {
            title: t('about.roadmap-stage'),
            date: '10-02-2023',
            subtitle: t('about.roadmap-subtitle'),
            icon: <Stage2Icon />,
        },
        {
            title: t('about.roadmap-stage'),
            date: '10-02-2023',
            subtitle: t('about.roadmap-subtitle'),
            icon: <Stage1Icon />,
        },
        {
            title: t('about.roadmap-stage'),
            date: '10-02-2023',
            subtitle: t('about.roadmap-subtitle'),
            icon: <Stage2Icon />,
        },
    ]

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <BaseLayout className="roadmap-view">
                <ScrollLayout classNameContent="roadmap-view__main">
                    <h1>Roadmap</h1>

                    <div className="roadmap-view__content">
                        {/* <div className="roadmap-view__content__divider" style={{ height: `calc(100% + ${stages.length}rem)` }}></div> */}

                        {stages.map(({ icon, title, date, subtitle }, index) => (
                            <div key={index} className="roadmap-view__step">
                                <i className="roadmap-view__step__icon">{icon}</i>

                                <div className="roadmap-view__step__detail">
                                    <h2>{`${title} ${index + 1}`}</h2>

                                    <h3>{date}</h3>

                                    <p>{subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </ScrollLayout>
            </BaseLayout>
        </motion.div>
    )
}

export default memo(RoadmapView)
