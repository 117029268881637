import { ModelViewerElement } from '@google/model-viewer'
import { FC, Suspense } from 'react'

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': Partial<ModelViewerElement>
        }
    }
}

export const ModelViewers: FC<{ src: string; alt: string; width?: string; height?: string; poster: string }> = ({
    src,
    width,
    height,
    poster,
    alt,
}) => {
    const style = { height: height ? height : '60vh', width: width ? width : '100vw' } as CSSStyleDeclaration

    return (
        <Suspense fallback={<>Loading...</>}>
            <model-viewer
                src={src}
                alt={alt}
                poster={poster}
                ar
                autoplay
                auto-rotate
                camera-controls
                loading="eager"
                touch-action="pan-y"
                shadow-intensity="1"
                style={style}
            ></model-viewer>
        </Suspense>
    )
}
