import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import global_en from './translations/en/global.json'
import global_es from './translations/es/global.json'

localStorage.getItem('language')! == undefined || localStorage.getItem('language')! == null
    ? localStorage.setItem('language', 'en')
    : localStorage.getItem('language')!

i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem('language')! || 'en',
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
