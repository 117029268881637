import { ReactNode } from 'react'
import { classNames } from '@utils'
import { toast } from 'react-toastify'
import { MdOutlineClose } from 'react-icons/md'
import './index.scss'

type themeToast = 'error' | 'success' | 'info' | 'warning'

interface notification {
    title: string
    subtitle?: string
    type: themeToast
    timer?: number
    icon?: ReactNode
}

const ContentMessage = ({ title, subtitle, type, icon }: any) => (
    <div className="notification__main">
        {icon && <i className={classNames('notification__icon', `notification__icon--${type}`)}>{icon}</i>}

        <div className="notification__content">
            <h3 className="notification__title">{title}</h3>

            {subtitle && <span className="notification__subtitle">{subtitle}</span>}
        </div>
    </div>
)

const Notifications = ({ title, subtitle, timer, type, icon }: notification) => {
    return toast(<ContentMessage title={title} subtitle={subtitle} type={type} icon={icon} />, {
        autoClose: timer ? timer : 3000,
        theme: 'light',
        type: type,
        closeButton: ({ closeToast }) => (
            <i onClick={closeToast} className="notification__close">
                <MdOutlineClose size={20} />
            </i>
        ),
        className: 'notification',
        icon: false,
    })
}

export default Notifications
