/* function validate regExp decimal */
export const validateDecimal = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,5}(\.)?$/)
    const regexD = new RegExp(/^\d{1,5}\.\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text) && !regexD.test(text)) {
            e.preventDefault()
        }
    }
} 

/* function validate regExp text */
export const validateInteger = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        let text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        text = text.replaceAll(',', '')

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp number */
export const validateNumber = (e: any) => {
    const regex = new RegExp(/^[0-9]*$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'v', 'c']

    /*  const charCode = e.which || e.keyCode
    if (charCode < 48 || charCode > 57 || e.ctrlKey) {
        e.preventDefault() // previene la entrada de letras y caracteres especiales usando Ctrl
    } */

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp text */
export const validateText = (e: any) => {
    const regex = new RegExp(/^[a-zA-Z\u00C0-\u017F\s]+$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text =
            selectedText.length > 0
                ? e.target.value.replace(selectedText, e.key)
                : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate email */
export const validationEmail = (v: any) => {
    const regex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(v)
}

export const validateName = (e: any) => {
    const value = e.target.value.replace(/\s{2,}/g, ' ')
    e.target.value = value
}

export const RegExpText = (v: any) => new RegExp(/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ\s]+$/).test(v)

export const RegExpNumber = (v: any) => new RegExp(/^[0-9]+$/).test(v) 
