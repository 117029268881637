export type TableContents = 'about' | 'experience' | 'advantages' | 'opportunity' | 'industry' | 'blockchain' | 'casinuu' | 'roadmap' | 'team'

export const titleContents = [
    {
        title: 'about',
        icon: 'https://gateway.pinata.cloud/ipfs/QmVLx93Up7DuwWaPotUzPAKouaQXNs1N2Uz68kuVTH2LU3',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmVLx93Up7DuwWaPotUzPAKouaQXNs1N2Uz68kuVTH2LU3_512.png',
    },
    {
        title: 'experience',
        icon: 'https://gateway.pinata.cloud/ipfs/QmNsXzxede5deZnQyRTjnpmeruHUehsyt5yRRRmqoMAFvU',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmNsXzxede5deZnQyRTjnpmeruHUehsyt5yRRRmqoMAFvU_512.png',
    },
    {
        title: 'advantages',
        icon: 'https://gateway.pinata.cloud/ipfs/QmSrnksS4WbJWY24eYcwHFDn1qSGUMWrV47UVBHocGToAv',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmSrnksS4WbJWY24eYcwHFDn1qSGUMWrV47UVBHocGToAv_512.png',
    },
    {
        title: 'opportunity',
        icon: 'https://gateway.pinata.cloud/ipfs/QmW7AoPSb5Krwt49qywjCDQSoSeYVmdt9ef7rAFWRTZGh9',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmW7AoPSb5Krwt49qywjCDQSoSeYVmdt9ef7rAFWRTZGh9_512.png',
    },
    {
        title: 'industry',
        icon: 'https://gateway.pinata.cloud/ipfs/QmdWogxKaPkcZHRieH6USLDSZMMHt48vqXdgHVJkC2wvuh',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmdWogxKaPkcZHRieH6USLDSZMMHt48vqXdgHVJkC2wvuh_512.png',
    },
    {
        title: 'casinuu',
        icon: 'https://gateway.pinata.cloud/ipfs/QmQzWKtXTztBeQoAddg6JWC6XB5RkNR7VuHKuna9X7ypDz',
        iconPng: 'https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmQzWKtXTztBeQoAddg6JWC6XB5RkNR7VuHKuna9X7ypDz_512.png',
    },
    /*  { title: 'roadmap', icon: '' }, */
    /* { title: 'team', icon: '' }, */
]
