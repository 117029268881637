import { useEffect, useState } from 'react'
import AppRoutes from './App.routes'
import useColorScheme from '@hooks/useColorScheme.hook'

function App() {
    const [online, setOnline] = useState<boolean>(navigator.onLine)

    /* color schema */
    const { enableColorScheme, disableColorScheme } = useColorScheme()

    /*  useEffect((): ReturnType<EffectCallback> => {
    enableLoader()

    return () => {
        disableLoader()
    }
}, []) */

    useEffect(() => {
        const handleOnline = () => setOnline(true)
        const handleOffline = () => setOnline(false)

        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    const initComponent = (): (() => void) => {
        enableColorScheme()

        window.scrollTo(0, 1)

        if (document.fullscreenElement) document.body.requestFullscreen()

        return () => {
            disableColorScheme()
        }
    }

    useEffect(initComponent, [])

    return <main className="App">{!online ? <p>Sin conexion</p> : <AppRoutes />}</main>
}

export default App
