import { useState, useMemo, memo, forwardRef } from 'react'
/* hook */
import { useTranslation } from 'react-i18next'
// import util
import { randomKey, classNames } from '@utils'
/* types */
import { ROLE_PARENT_MAP } from '@types'
/* assets */
import { FaEye, FaEyeSlash } from 'react-icons/fa'
/* styles */
import './index.scss'

export type inputType = 'text' | 'date' | 'checkbox' | 'file' | 'switch' | 'password'

interface Props {
    label?: string
    type?: inputType
    className?: string
    placeholder?: string
    errorMessage?: string
    hint?: string
    isHintReserved?: boolean
    role?: ROLE_PARENT_MAP
    disabled?: boolean
    keepBorder?: boolean
    isFit?: boolean
    beforeIcon?: any
    afterIcon?: any
    isBackground?: boolean
    afterContent?: any
    [x: string]: any
}

const TextField = forwardRef<HTMLInputElement, Props>(
    (
        {
            label = '',
            type = 'text',
            className,
            placeholder = '',
            errorMessage,
            hint,
            isHintReserved = false,
            role = ROLE_PARENT_MAP.NONE,
            disabled,
            keepBorder = false,
            isFit = false,
            beforeIcon,
            afterIcon,
            isBackground = false,
            afterContent,
            ...rest
        },
        ref
    ) => {
        // random id to assing to input field & make reference from label
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const idFieldText: string = useMemo((): string => randomKey().key, [])

        const [showPassword, setShowPassword] = useState(false)

        const [t] = useTranslation('global')

        return (
            <>
                <fieldset
                    className={classNames(
                        'text-field',
                        errorMessage ? ROLE_PARENT_MAP.DANGER : role || ROLE_PARENT_MAP[role],
                        isFit ? 'text-field--fit' : '',
                        type === 'file' ? 'text-field--file' : '',
                        type === 'checkbox' ? 'text-field--checkbox' : '',
                        type === 'switch' ? 'text-field--switch' : '',
                        className
                    )}
                    disabled={disabled}
                >
                    {(label || type === 'switch') && (
                        <label
                            className={classNames(
                                'text-field__label',
                                type === 'switch' ? 'text-field__label--switch' : '',
                                disabled ? 'text-field__label--disabled' : ''
                            )}
                            htmlFor={idFieldText}
                        >
                            {type === 'switch' && (
                                <>
                                    <input type="checkbox" id={idFieldText} autoComplete="off" {...rest} ref={ref} />
                                    <span />
                                </>
                            )}

                            {label && <span>{label}</span>}
                        </label>
                    )}
                    {/* ...rest assing other props passed into component invoke */}

                    <div
                        className={classNames(
                            'text-field__content',
                            type === 'switch' ? 'text-field__content--hidden' : '',
                            keepBorder ? 'text-field__content--keep' : '',
                            isBackground ? 'text-field__content--background' : '',
                            disabled ? 'text-field__content--disabled' : '',
                            className
                        )}
                    >
                        {beforeIcon && (
                            <i className={classNames('text-field__content-bi', isBackground ? 'text-field__content--background-bi' : '')}>
                                {beforeIcon}
                            </i>
                        )}

                        <input
                            id={idFieldText}
                            placeholder={placeholder.length <= 0 ? t('input.placeholder') : placeholder}
                            className={classNames(
                                type === 'date' ? 'text-field__content__date' : '',
                                type === 'file' ? 'text-field__content__file' : '',
                                type === 'switch' ? 'text-field__content__switch' : ''
                            )}
                            type={type === 'password' && showPassword ? 'text' : type === 'switch' ? 'checkbox' : type}
                            disabled={type === 'switch'}
                            autoComplete="off"
                            autoFocus={false}
                            ref={ref}
                            {...rest}
                        />

                        {type === 'password' && (
                            <i
                                className="text-field__content-pass"
                                onClick={() => {
                                    !disabled ? setShowPassword(!showPassword) : undefined
                                }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </i>
                        )}

                        {afterIcon && (
                            <div
                                className={classNames(
                                    'text-field__content-ai',
                                    'text-field__content-ai--interactive',
                                    isBackground ? 'text-field__content--background-ai' : ''
                                )}
                            >
                                {afterIcon}
                            </div>
                        )}

                        {afterContent && afterContent()}
                    </div>

                    {errorMessage ? (
                        <span className="text-field__hint">{errorMessage}</span>
                    ) : hint ? (
                        <span className="text-field__hint">{hint}</span>
                    ) : (
                        isHintReserved && <span className="text-field__reserved" />
                    )}
                </fieldset>
            </>
        )
    }
)

export default memo(TextField)
