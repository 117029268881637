import { FC, memo } from 'react'
/* hooks */
import { useTranslation } from 'react-i18next'
/* layouts */
import { BaseLayout } from '@layouts'
/* utils */
import { classNames } from '@utils'
/* types  */
import { titleContents } from '@types'
/* assets */
import { CupIcon } from '@assets'
/* styles */
import './index.scss'

const Sidebar: FC<{ className?: string; active: string; setActive: any; setTitleC: any }> = ({
    className,
    active,
    setActive,
    setTitleC,
    ...rest
}) => {
    const [t] = useTranslation('global')

    return (
        <BaseLayout className={classNames('sidebar', className)} {...rest}>
            <main className="sidebar__main">
                <h3 className="sidebar__title">{t('about.contents')}</h3>

                <div className="sidebar__content">
                    <span
                        className={classNames(
                            'sidebar__item',
                            active === titleContents[0].title ? 'sidebar__item--active-roadmap' : '',
                            'animate__animated animate__fadeIn animate__faster'
                        )}
                        onClick={() => {
                            setTitleC(titleContents[0].title)
                            setActive(titleContents[0].title)
                        }}
                    >
                        {t(`about.${titleContents[0].title}`)}
                        <i>
                            <CupIcon />
                        </i>
                    </span>
                </div>

                {/* <h3 className="sidebar__title">Whitepaper</h3> */}

                <div className="sidebar__content">
                    <span className="sidebar__content--hidden"></span>

                    <div className="sidebar__container">
                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[1].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[1].title)
                                setActive(titleContents[1].title)
                            }}
                        >
                            {t(`about.${titleContents[1].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span>

                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[2].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[2].title)
                                setActive(titleContents[2].title)
                            }}
                        >
                            {t(`about.${titleContents[2].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span>

                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[3].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[3].title)
                                setActive(titleContents[3].title)
                            }}
                        >
                            {t(`about.${titleContents[3].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span>

                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[4].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[4].title)
                                setActive(titleContents[4].title)
                            }}
                        >
                            {t(`about.${titleContents[4].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span>

                        {/* <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[5].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[5].title)
                                setActive(titleContents[5].title)
                            }}
                        >
                            {t(`about.${titleContents[5].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span> */}

                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[5].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[5].title)
                                setActive(titleContents[5].title)
                            }}
                        >
                            {t(`about.${titleContents[5].title}`)}

                            <i>
                                <CupIcon />
                            </i>
                        </span>

                        {/* <div className="sidebar__divider"></div>

                        <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[7].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[7].title)
                                setActive(titleContents[7].title)
                            }}
                        >
                            {t(`about.${titleContents[7].title}`)}

                            <i>
                                <CupIcon />
                            </i>
                        </span> */}

                        {/* <span
                            className={classNames(
                                'sidebar__item',
                                active === titleContents[8].title ? 'sidebar__item--active-roadmap' : '',
                                'animate__animated animate__fadeIn animate__faster'
                            )}
                            onClick={() => {
                                setTitleC(titleContents[8].title)
                                setActive(titleContents[8].title)
                            }}
                        >
                            {t(`about.${titleContents[8].title}`)}
                            <i>
                                <CupIcon />
                            </i>
                        </span> */}
                    </div>
                </div>
            </main>
        </BaseLayout>
    )
}

export default memo(Sidebar)
