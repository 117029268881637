import classNames from '@utils/class-names.util'
import { FC, PropsWithChildren } from 'react'
import { Link as RLink, useMatch } from 'react-router-dom'

const NavLink: FC<
    PropsWithChildren<{
        to: string
        activeClassName?: string
        className?: string
        onClick?: () => void
        isTarget?: boolean
    }>
> = ({ children, to, activeClassName, onClick, className = '', isTarget = false, ...rest }) => {
    const match = useMatch(to)

    return (
        <RLink
            to={to}
            className={classNames(
                className,
                match !== null ? activeClassName : undefined
            )}
            onClick={onClick}
            {...rest}
            target={isTarget ? '_blank' : ''}
        >
            {children}
        </RLink>
    )
}

export default NavLink
