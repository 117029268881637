// react
import { useEffect } from 'react'
// hooks
import { useSessionStorage } from '.'
// types
import { ColorScheme } from '@types'

/* themes */
const THEMES = {
    LIGHT: 'theme--light',
    DARK: 'theme--dark',
}

const useColorScheme = () => {
    /* get predefined color scheme */
    const [scheme, setScheme] = useSessionStorage('color_scheme', ColorScheme.LIGHT)

    /* set user defined color scheme */
    const setColorScheme = colorScheme => setScheme(colorScheme)

    /* verify if the user set the system preference */
    useEffect(() => {
        const colorScheme =
            scheme === ColorScheme.DARK
                ? window.matchMedia('(prefers-color-scheme: dark)').matches
                    ? ColorScheme.DARK
                    : ColorScheme.LIGHT
                : scheme

        window.dispatchEvent(
            new CustomEvent('_onChangeColorScheme', {
                detail: {
                    colorScheme,
                },
                bubbles: true,
                cancelable: true,
            })
        )
    }, [scheme])

    /* apply color scheme */
    const _onChangeColorScheme = ({ detail }) => {
        Object.keys(THEMES).forEach(key => document.body.classList.remove(THEMES[key]))
        document.body.classList.add(THEMES[detail?.colorScheme])
    }

    /* enable custom listeners */
    const enableColorScheme = () => {
        window.addEventListener('_onChangeColorScheme', _onChangeColorScheme)
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            //if (scheme !== ColorScheme.SYSTEM) return

            const colorScheme = e.matches ? ColorScheme.DARK : ColorScheme.LIGHT

            window.dispatchEvent(
                new CustomEvent('_onChangeColorScheme', {
                    detail: {
                        colorScheme,
                    },
                    bubbles: true,
                    cancelable: true,
                })
            )
        })

        const colorScheme =
            scheme === ColorScheme.DARK
                ? window.matchMedia('(prefers-color-scheme: dark)').matches
                    ? ColorScheme.DARK
                    : ColorScheme.LIGHT
                : scheme

        window.dispatchEvent(
            new CustomEvent('_onChangeColorScheme', {
                detail: {
                    colorScheme,
                },
                bubbles: true,
                cancelable: true,
            })
        )
    }

    /* disable custom listeners */
    const disableColorScheme = () => window.removeEventListener('_onChangeColorScheme', _onChangeColorScheme)
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {})

    return {
        colorScheme: scheme,
        setColorScheme,
        enableColorScheme,
        disableColorScheme,
    }
}

export default useColorScheme
