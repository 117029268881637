/* react */
import { FC, memo } from 'react'
/* utils */
import { classNames } from '@utils'
/* assets */
import { LogoTipo } from '@assets'
import { AiOutlineMenu } from 'react-icons/ai'
/* styles */
import './index.scss'

const MobileNavbar: FC<{ className?: string; onClick: () => void }> = ({ className, onClick, ...rest }) => {
    return (
        <header className={classNames('mobile-navbar', className)} {...rest}>
            <div className="mobile-navbar__main">
                <div className="mobile-navbar__logo">
                    <i>
                        <LogoTipo />
                    </i>
                </div>

                <i className={classNames('mobile-navbar__menu', 'animate__animated animate__fadeIn animate__faster')} onClick={onClick}>
                    <AiOutlineMenu size={32} />
                </i>
            </div>
        </header>
    )
}

export default memo(MobileNavbar)
