/* react */
import { memo, useEffect } from 'react'
/* hooks */
import { useTranslation } from 'react-i18next'
/* utils */
import { randomKey } from '@utils'
/* types */
import { navItems } from '@types'
/* componentes */
import { NavLink } from '@components'
/* assets */
import { ItechLogo, LegalLogo, LogoTipo } from '@assets'
import { FaFacebookF, FaInstagram, FaRedditAlien } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { RiGitRepositoryPrivateFill } from 'react-icons/ri'
import { HiMiniClipboardDocumentList } from 'react-icons/hi2'
/* styles */
import './index.scss'

const Footer = () => {
    const [t] = useTranslation('global')

    const lang = localStorage.getItem('language')!

    const partners = [{ icon: <ItechLogo /> }, { icon: <LegalLogo /> }]

    useEffect(() => {
        ;(window as any)?.anj_6b32b103_7f72_4d9d_8b23_0c8e27451f21?.init()
    }, [])

    return (
        <div className="footer">
            <section>
                <div className="footer__content">
                    <div className="footer__logo">
                        <i>
                            <LogoTipo />
                        </i>

                        <div className="footer__title">
                            <div className="footer__title__container">
                                <span>{t('footer.title')}</span>

                                <span>{t('footer.subtitle')}</span>
                            </div>

                            <div className="footer__title__contents">
                                <div
                                    id="anj-6b32b103-7f72-4d9d-8b23-0c8e27451f21"
                                    data-anj-seal-id="6b32b103-7f72-4d9d-8b230c8e27451f21"
                                    data-anj-image-size="50"
                                    data-anj-image-type="basic-small"
                                ></div>

                                {partners.map(({ icon }, index) => (
                                    <i key={index}>{icon}</i>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="footer__contact">
                        <div>
                            <h2>Menu</h2>

                            <nav>
                                <ul className="footer__options">
                                    {navItems.map(({ title, path, icon }) => (
                                        <li {...randomKey()}>
                                            <NavLink
                                                to={title == 'marketplace' ? path.concat(`?lang=${lang}`) : path}
                                                className="footer__option"
                                                activeClassName="footer__option--active"
                                                isTarget={title == 'marketplace' ? true : false}
                                            >
                                                <i>{icon}</i>

                                                <span>{t(`navbar.${title}`)}</span>
                                            </NavLink>
                                        </li>
                                    ))}

                                    <li style={{ zIndex: 1 }}>
                                        <NavLink to="/privacy-policy" className="footer__option" activeClassName="footer__option--active">
                                            <i>
                                                <RiGitRepositoryPrivateFill />
                                            </i>

                                            <span>{t(`submenu.policy`)}</span>
                                        </NavLink>
                                    </li>

                                    <li style={{ zIndex: 1 }}>
                                        <NavLink to="/terms-and-conditions" className="footer__option" activeClassName="footer__option--active">
                                            <i>
                                                <HiMiniClipboardDocumentList />
                                            </i>

                                            <span>{t(`submenu.terms`)}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div>
                            <h2>Contacts</h2>

                            <a href="mailto:info@casinuu.com">info@casinuu.com</a>
                        </div>
                    </div>
                </div>

                <div className="footer__container">
                    <span>© 2024. Casinuu</span>

                    <div>
                        <i>{/* <LegalLogo /> */}</i>

                        <div>
                            <a href="https://x.com/CasinuuOfficial" target="_blank">
                                <i>
                                    <FaSquareXTwitter />
                                </i>
                            </a>

                            <a href="https://www.facebook.com/Casinuu.official/" target="_blank">
                                {' '}
                                <i>
                                    <FaFacebookF />
                                </i>
                            </a>

                            <a href="https://www.reddit.com/user/Casinuu" target="_blank">
                                <i>
                                    <FaRedditAlien />
                                </i>
                            </a>

                            <a href="https://www.instagram.com/casinuu/" target="_blank">
                                <i>
                                    <FaInstagram />
                                </i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default memo(Footer)
