/* react */
import { memo, FC, PropsWithChildren } from 'react'
/* hooks */
import { useActive } from '@hooks/useActive.hook'
/* layouts */
import { BaseLayout, ScrollLayout } from '..'
/* utils */
import { classNames } from '@utils'
/* types */
import { SCROLL_MAP } from '@types'
/* components */
import { Footer, MobileNavbar, MobileSidebar, Navbar } from '@components'
import { ToastContainer } from 'react-toastify'
import { motion } from 'framer-motion'
/* styles */
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'

const DashboardLayout: FC<
    PropsWithChildren<{
        className?: string
        onChangeRight?: any
        onChangeLeft?: any
        iconRight?: any
        iconLeft?: any
        isBackground?: boolean
        footer?: boolean
    }>
> = ({ children, className, isBackground, footer = false }) => {
    const [isMobileMenu, showMobileMenu, hideMobileMenu] = useActive()

    const deviceType = () => {
        const ua = navigator.userAgent
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'tablet'
        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return 'mobile'
        }
        return 'desktop'
    }

    return (
        <BaseLayout className="dashboard">
            <div className="dashboard__main">
                <Navbar className="dashboard__sidebar" isBackground={isBackground} />

                <BaseLayout className="dashboard__content">
                    <MobileNavbar className="dashboard__mobile-navbar" onClick={() => showMobileMenu()} />

                    <ScrollLayout
                        classNameContent={classNames(
                            'dashboard__container',
                            deviceType() === 'mobile' ? 'dashboard__container--mobile' : 'dashboard__container',
                            deviceType() === 'tablet' ? 'dashboard__container--tablet' : 'dashboard__container',
                            className
                        )}
                        scroll={SCROLL_MAP.Y}
                    >
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            {children}
                        </motion.div>

                        {footer && <Footer />}
                    </ScrollLayout>
                </BaseLayout>
            </div>

            <MobileSidebar isModal={isMobileMenu} hideModal={hideMobileMenu} />

            <ToastContainer
                toastStyle={{ width: '100%' }}
                position="top-right"
                hideProgressBar
                autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
        </BaseLayout>
    )
}

export default memo(DashboardLayout)
