/* react */
import { memo, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
/* hooks */
import { useTranslation } from 'react-i18next'
/* layouts */
import { DashboardLayout } from '@layouts'
/* hooks */
import { useMinWidth } from '@hooks'
/* utils */
import { matchBreakPoint } from '@utils'
/* components */
import { Button, ModelViewers } from '@components'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* assets */
import {
    BGSlot,
    BitcoinIcon,
    CertificateLogo,
    EthereumIcon,
    ExperienceIcon,
    FlagIcon,
    GameIcon,
    Games1Logo,
    GlassesIcon,
    InvesmentIcon,
    MetamaskIcon,
    MoneyMacLogo,
    PaypalLogo,
    SecuratyIcon,
    ShopIcon,
    SummusIcon,
    TetherIcon,
} from '@assets'
import { HiOutlineArrowRight } from 'react-icons/hi'
/* styles */
import './index.scss'

const HomeView = () => {
    const [searchParams] = useSearchParams()

    const [t, i18n] = useTranslation('global')

    const language = localStorage.getItem('language')!

    const lang = searchParams.get('lang')

    const navigate = useNavigate()

    useEffect(() => {
        if (lang == null) return

        i18n.changeLanguage(lang!).then(() => {
            localStorage.setItem('language', lang!)

            // Establecer un temporizador para eliminar el parámetro 'lang' después de 5 segundos
            const timer = setTimeout(() => {
                searchParams.delete('lang')
                navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true })
            }, 5000)

            // Limpiar el temporizador si el componente se desmonta antes de que se cumplan los 5 segundos
            return () => clearTimeout(timer)
        })
    }, [lang, searchParams, navigate])

    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('lg', breakPoint)

    const wallets = [
        { icon: <BitcoinIcon /> },
        { icon: <EthereumIcon /> },
        { icon: <TetherIcon /> },
        { icon: <MetamaskIcon /> },
        { icon: <PaypalLogo /> },
    ]

    const partners = [{ icon: <SummusIcon /> }]

    const subtitleCasino = [
        { title: t('home.casino-subtitle1'), subtitle: t('home.casino-list1'), icon: <GlassesIcon /> },
        { title: t('home.casino-subtitle2'), subtitle: t('home.casino-list2'), icon: <ExperienceIcon /> },
        { title: t('home.casino-subtitle3'), subtitle: t('home.casino-list3'), icon: <InvesmentIcon /> },
        { title: t('home.casino-subtitle4'), subtitle: t('home.casino-list4'), icon: <SecuratyIcon /> },
    ]

    const subtitleGame = [
        { title: t('home.games-subtitle-2'), icon: <Games1Logo /> },
        { title: t('home.games-subtitle-3'), icon: <CertificateLogo /> },
        { title: t('home.games-subtitle-4'), icon: <MoneyMacLogo /> },
    ]

    return (
        <DashboardLayout className="home-view" footer>
            <main className="home-view__main">
                <section>
                    <div className="home-view__container">
                        <div className="home-view__content">
                            <div className="home-view__content__details">
                                <h2>{t('home.welcome')}</h2>

                                <h1>
                                    {t('home.title-1')} <h1 className="home-view__content__details--green">{t('home.title-2')}</h1>{' '}
                                    {t('home.title-3')}
                                </h1>

                                <h3>{t('home.subtitle')}</h3>

                                <div className="home-view__content__details__actions">
                                    <Button
                                        variant={VARIANT_BUTTON_MAP.OUTLINE}
                                        onClick={() => document.getElementById('roadmap')?.scrollIntoView({ behavior: 'smooth' })}
                                    >
                                        {t('home.learn-more')}
                                    </Button>

                                    <a href={`https://casinuu.com/app/marketplace?lang=${language}`} target="_blank">
                                        <Button variant={VARIANT_BUTTON_MAP.FILL} role={ROLE_BUTTON_MAP.SECONDARY} rightIcon={<ShopIcon />}>
                                            {t('home.view-marketplace')}
                                        </Button>
                                    </a>
                                </div>
                            </div>

                            <div className="home-view__content__video">
                                <video width="100%" height={'100%'} autoPlay playsInline loop muted>
                                    <source
                                        src="https://casinuu.s3.ap-southeast-1.amazonaws.com/branding/Casinuulandinghorizontalnoaudiov2Encoded.mp4"
                                        type="video/mp4"
                                    />
                                    <source
                                        src="https://casinuu.s3.ap-southeast-1.amazonaws.com/branding/CasinuuLandingHorizontalNoAudioV2.webm"
                                        type="video/webm"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="roadmap">
                    <div className="home-view__roadmap">
                        <a href={`https://casinuu.com/app/marketplace?lang=${language}`} target="_blank" style={{ textDecoration: 'none' }}>
                            <Button
                                variant={VARIANT_BUTTON_MAP.FILL}
                                role={ROLE_BUTTON_MAP.SECONDARY}
                                rightIcon={<HiOutlineArrowRight />}
                                leftIcon={<GameIcon />}
                                className="home-view__roadmap__action"
                            >
                                {t('home.roadmap')}
                            </Button>
                        </a>

                        <div className="home-view__roadmap__details">
                            <h2>{t('home.welcome')}</h2>

                            <h1>{t('home.roadmap-title')}</h1>

                            <h3>{t('home.roadmap-subtitle')}</h3>

                            <div className="home-view__roadmap__details__grid">
                                <span>{t('home.roadmap-grid1')}</span>
                                <span>{t('home.roadmap-grid2')}</span>
                                <span>{t('home.roadmap-grid3')}</span>
                            </div>

                            <h1>{t('home.roadmap-subtitle2')}</h1>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="home-view__casinuu">
                        <div className="home-view__casinuu__content">
                            <div className="home-view__casinuu__content__model">
                                <ModelViewers
                                    src="https://gateway.pinata.cloud/ipfs/QmbLxNJzeXYALDM79kGhBSz935jKDdyLcsgQUj86SnHYPo"
                                    poster="https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmbLxNJzeXYALDM79kGhBSz935jKDdyLcsgQUj86SnHYPo_512.png"
                                    alt={'model'}
                                    // height={isMobile.under ? '50vh' : '30vh'}
                                    width={isMobile.under ? '80vw' : '30vw'}
                                />
                            </div>

                            <div className="home-view__casinuu__details">
                                <h1>{t('home.casino-title')}</h1>

                                <div className="home-view__casinuu__details__grid">
                                    {subtitleCasino.map((s, index) => (
                                        <div key={index} className="home-view__casinuu__details__list">
                                            <i>{s.icon}</i>

                                            <div>
                                                <h2>{s.title}</h2>

                                                <span>{s.subtitle}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="home-view__games">
                        <div className="home-view__games__content">
                            <div className="home-view__games__title">
                                <h1>{t('home.games-title')}</h1>

                                <span>{t('home.games-subtitle')}</span>

                                {subtitleGame.map((g, index) => (
                                    <div key={index} className="home-view__games__list">
                                        <i>{g.icon}</i>

                                        <span>{g.title}</span>
                                    </div>
                                ))}
                            </div>

                            {/* <img
                                loading="eager"
                                alt="carts"
                                src="https://casinuu.s3.ap-southeast-1.amazonaws.com/assets/background-4-min.png"
                                className="home-view__games__casino-chips"
                            /> */}

                            <div className="home-view__games__model">
                                <ModelViewers
                                    src="https://gateway.pinata.cloud/ipfs/QmXDHHhKkS1JTxpJyX4QH9tLQR3x9KtcW8yf363zvQVVvZ"
                                    poster="https://casinuu-machines.s3.ap-southeast-1.amazonaws.com/QmXDHHhKkS1JTxpJyX4QH9tLQR3x9KtcW8yf363zvQVVvZ_512.png"
                                    alt={'model'}
                                    // height={isMobile.under ? '50vh' : '30vh'}
                                    width={isMobile.under ? '80vw' : '30vw'}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="home-view__machines">
                        <div className="home-view__machines__content">
                            <div className="home-view__machines__bg">
                                <img
                                    loading="eager"
                                    src={BGSlot}
                                    // src="https://casinuu.s3.ap-southeast-1.amazonaws.com/assets/background-3-min.jpg"
                                    alt="machine"
                                    className="home-view__machines__bg"
                                />
                            </div>

                            <div className="home-view__machines__content__title">
                                <div className="home-view__machines__title">
                                    <h1>
                                        {t('home.machine-title')}
                                        <i>
                                            <FlagIcon />
                                        </i>
                                    </h1>

                                    <h3>
                                        {t('home.machine-subtitle')} <span>{t('home.machine-subtitle2')}.</span>
                                    </h3>

                                    <a href={`https://casinuu.com/app/marketplace?lang=${language}`} target="_blank">
                                        <Button variant={VARIANT_BUTTON_MAP.FILL} role={ROLE_BUTTON_MAP.SECONDARY} rightIcon={<ShopIcon />}>
                                            {t('home.view-marketplace')}
                                        </Button>
                                    </a>
                                </div>

                                <img
                                    loading="eager"
                                    src="https://casinuu.s3.ap-southeast-1.amazonaws.com/assets/background-5-min.png"
                                    alt="machine"
                                    className="home-view__machines__icon"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="home-view__wallets">
                        <div className="home-view__wallets__title">
                            <h1>{t('home.wallet-title')}</h1>

                            <span>{t('home.wallet-subtitle')}</span>

                            <h2>{t('home.wallet-subtitle2')}</h2>

                            <h3>{t('home.wallet-title-2')}</h3>
                        </div>

                        <div className="home-view__wallets__contents">
                            {wallets.map(({ icon }, index) => (
                                <i key={index}>{icon}</i>
                            ))}
                        </div>
                    </div>
                </section>

                <section>
                    <div className="home-view__partners">
                        <div className="home-view__partners__container">
                            <div className="home-view__partners__title">
                                <h1>{t('home.partners')}</h1>
                            </div>

                            <div className="home-view__partners__contents">
                                {partners.map(({ icon }, index) => (
                                    <i key={index}>{icon}</i>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default memo(HomeView)
