/* react */
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
/* utils */
import { classNames } from '@utils'
/* components */
import { NavLink } from '..'
/* styles */
import './index.scss'

const Submenu: FC<{ className?: string; menuItem: any }> = ({ menuItem, className, ...rest }) => {
    const [t] = useTranslation('global')

    return (
        <div className={classNames('sub-menu', className)} {...rest}>
            {menuItem.map(({ title, path, icon }: any, index: any) => (
                <NavLink key={index} to={path} className="sub-menu__item" activeClassName="sub-menu__item--active">
                    {icon && <i>{icon}</i>}

                    <h3>{t(`submenu.${title}`)}</h3>
                </NavLink>
            ))}
        </div>
    )
}

export default memo(Submenu)
