// react
import { FC, memo } from 'react'
// components
import { ModalLayout } from '@layouts'
/* React Loader Spinner */
import { TailSpin } from 'react-loader-spinner'
// utils
import { classNames } from '@utils'
// types
import { Role, Size } from '@types'
// styles
import './index.scss'

/* Map the loader role */
const ROLE_LOADER_MAP = {
    PRIMARY: '#004581',
    SECONDARY: '#000',
    ACCENT: '#000',
    INFO: '#000',
    SUCCESS: '#00b01d',
    WARNING: '#f2b413',
    DANGER: '#f01e1e',
    NONE: '#d2d4da',
}

/* Map the loader size */
const SIZE_LOADER_MAP = {
    xs: { width: 16, height: 16 },
    sm: { width: 24, height: 24 },
    md: { width: 48, height: 48 },
    lg: { width: 64, height: 64 },
    xl: { width: 96, height: 96 },
    xl2: { width: 128, height: 128 },
}

/**
 * Custom component to manage loaders
 * @param className - style to be applied on 'div' wrapper
 * @param overlay - to show as user center attention
 * @param role - current color schema
 * @param size - current loader size
 * @param isLoading - is loader show
 * @param type - current loader design
 * @returns Component loader with overlay support
 */
const LoaderComponent: FC<{ className?: string; overlay?: boolean; role?: Role; size: Size; isLoading: boolean }> = ({
    className,
    overlay = false,
    role = Role.PRIMARY,
    size = Size.md,
    isLoading,
}) => {
    const loader = () => (
        <div className={classNames('loader', overlay ? 'loader--overlay' : '', className)}>
            <TailSpin
                color={ROLE_LOADER_MAP[role] || ROLE_LOADER_MAP.NONE}
                width={SIZE_LOADER_MAP[size].width}
                height={SIZE_LOADER_MAP[size].height}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )

    return (
        <>
            {overlay ? (
                <ModalLayout isModal={isLoading} portal="loader">
                    {loader()}
                </ModalLayout>
            ) : (
                isLoading && loader()
            )}
        </>
    )
}

export default memo(LoaderComponent)
