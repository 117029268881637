// react
import { lazy } from 'react'

import HomeView from './HomeView'
//import WhitepaperView from './WhitepaperView'
// import ContactView from './ContactView'
import RoadmapView from './RoadmapView'

// const HomeView = lazy(() => import('./HomeView'))
const WhitepaperView = lazy(() => import('./WhitepaperView'))
const ContactView = lazy(() => import('./ContactView'))
const TermsConditionsView = lazy(() => import('./TermsConditionsView'))
const PrivacyPolicyView = lazy(() => import('./PrivacyPolicyView'))

export { HomeView, WhitepaperView, ContactView, RoadmapView, PrivacyPolicyView, TermsConditionsView }
