/* react */
import { FC, PropsWithChildren, memo } from 'react'
import { classNames } from '@utils'
/* assets */
import { MachineIcon } from '@assets'
/* styles */
import './index.scss'

const Banner: FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
    return (
        <header className={classNames('banner', className)}>
            <div className="banner__background">
                <i>
                    <MachineIcon />
                </i>

                <div className="banner__content">{children}</div>
            </div>
        </header>
    )
}

export default memo(Banner)
